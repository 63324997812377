@import '@/components/variables.scss';

:root {
  --spacer: 12px;
  --borderColor: #DDDDDD;
  --primaryColor: #4287f5;
  --primaryText: #FFFFFF;
  --secondaryColor: #000000;
  --secondaryText: #FFFFFF;
  --headerText: #FFFFFF;
  --redColor: #DD0000;
  --colorYellow: #E05E2A;
  --colorRed: #DD0000;
  --colorGreen: #24a924;
  --lightGrayColor: #eeeeee;
  --lightBlueColor: #dbe8f6;
  --buttonBorderRadius: 6px;
  --fieldBg: #FFFFFF;
  --fieldDisabledBg: #F5F5F5;
  --fieldBorderWidth: 1px;
  --fieldBorderColor: #DDDDDD;
  --fieldText: #000000;
  --fieldOutlineColor: #4287f5;
  --fieldBorderRadius: 6px;
  --buttonBorderRadius: 6px;
  --tileBorderRadius: 12px;
  --tileBoxShadow: 0 0 10px rgba(0,0,0,01);
  --tileBorder: 0px;
  --tilesSpacing: 24px;
  --textHoverColor: #EEEEEE;
  --dropdownArrow: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23999999%22%20height%3D%22512%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%22512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20clip-rule%3D%22evenodd%22%20d%3D%22m4.93934%2010.9393c.58579-.5857%201.53553-.5857%202.12132%200l8.93934%208.9394%208.9393-8.9394c.5858-.5857%201.5356-.5857%202.1214%200%20.5857.5858.5857%201.5356%200%202.1214l-10%2010c-.5858.5857-1.5356.5857-2.1214%200l-9.99996-10c-.58579-.5858-.58579-1.5356%200-2.1214z%22%20fill%3D%22%23999999%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
}

* {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

input, textarea, select, button {
  appearance: none;
  outline: none;
  background: none;
  box-shadow: none;
  border: 0;
  font-family: inherit;
}

.running {
  animation: processingSpinner 1s infinite;
}
@keyframes processingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text-white { color: white }
.text-red { color: var(--colorRed) }
.text-bold { font-weight: 700 }
.text-green { color: var(--colorGreen) }
.text-gray { color: #999999 }

.text-icon { 
  font-size: 16px;
  svg {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: -4px;
  }
}

.toast {
  display: block;
  padding: 12px;
  font-size: 12px;
}

.---hiidden .toast {
  opacity: 0 !important;
}

div:has(.next-error-h1) {
  background-color: var(--primaryColor);
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-box {
    flex: 0 0 auto;
  }
}

@each $bp in $x-breakpoints {
  .min-b-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-b-#{$bp} {
      display: block !important;
    }
  }
  .min-i-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .min-f-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-f-#{$bp} {
      display: flex !important;
    }
  }
  .max-b-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-b-#{$bp} {
      display: block !important;
    }
  }
  .max-i-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .max-f-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-f-#{$bp} {
      display: flex !important;
    }
  }
}
